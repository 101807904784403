import { Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef, Input, Renderer2 } from '@angular/core';
import { NgwWowService } from 'ngx-wow';
import { WebsiteService } from './services/website.service';
import { WebPublicData, GalleryWeb, ClubWeb, StayWeb, BodyTour, BodyMore, BodySocial, BodyOther, WebDays } from './models/website.model';
import { BookingService } from '../admin/services/booking.service';
import { Booking } from '../admin/models/booking.model';
import { Router, ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { DateFilterFn } from '@angular/material/datepicker';

import { MatDialog } from '@angular/material/dialog';
import { TermsConditionComponent } from './components/terms-condition/terms-condition.component';
import { RefundPolicyComponent } from './components/refund-policy/refund-policy.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { DatePipe } from '@angular/common';


import * as moment from 'moment-timezone';

declare const bootstrap: any;

interface ReviewHgl {
  date: string;
  name: string;
  rating: number;
  desc: string;
}

@Component({
  selector: 'app-website',
  templateUrl: './website.component.html',
  styleUrls: ['./website.component.css'],
  providers: [DatePipe]
})
export class WebsiteComponent implements OnInit {

  webPublicData!:WebPublicData;
  priceFromArray?: any[];
  tourArray?: BodyTour[];
  moreArray?: BodyMore[];
  socialArray?: BodySocial[];
  footerHeaderArray?: BodyOther[];
  galleryWeb?: GalleryWeb[];
  clubWeb?: ClubWeb[];
  stayWeb?: StayWeb[];

  @ViewChild('videoplayer') videoplayer: ElementRef<HTMLVideoElement> | undefined;
  @ViewChild('carousel') carousel!: ElementRef;
  isModalOpen: boolean = false;
  modalImageUrl: string | null = null;

  showSection: boolean = false;

  webCity!: string;
  inputName = '';
  inputPhone = '';
  inputEmail = '';
  inputDate: Date | null = null;
  dateToSql = '';
  inputPaymentType = 'Direct';
  inputTotalPeople = 1;
  inputPricePerPerson = 0;

  subtotal!:number;
  fee!:number;
  total!:number;

  @ViewChild('paymentRef', {static: true}) paymentRef!: ElementRef;
  isCompletedPayment: boolean = false;
  isErrorPayment: boolean = false;
  isDisabled: boolean = true;

  panelOpenState = false;
  reviewOpenState = false;

  @ViewChild('gallerySection') gallerySection!: ElementRef;

  @Input() min: any;
  yesterday = new Date();
  startingDate = new Date();
  timezone: string = 'Europe/Belgrade';
  webDays?: WebDays [];
  selected: boolean[] = [false, false, true, false, false, true, false];
  workDaysNames: string[] = [];
  blockedDates: Date[] = [];
  workDays:any = [];
  hours?: string;

  faqItems:any = [];
  webActive?: boolean;
  seoKeywords?: string;

  inputReviewName = '';
  inputReviewDesc = '';
  setRating: number = 0;
  reviewDate: Date | undefined
  stars: number[] = [1, 2, 3, 4, 5];
  starsArray: number[] = [];
  isDisabledRew: boolean = true;
  successReview: boolean = false;
  rating = 0;
  fullStars!: number[];
  halfStar!: boolean;
  emptyStars!: number[];
  totalReviews = 0;

  reviewsArray: any[] = [];
  highlightedReview: ReviewHgl | null = null;
  fullStarsHgh!: number[];

  constructor(private renderer: Renderer2, public dialog: MatDialog, private titleService: Title, private metaService: Meta, private wowService: NgwWowService, private datePipe: DatePipe, private websiteService: WebsiteService, private cdr: ChangeDetectorRef, private elRef: ElementRef, private bookingService: BookingService, private router: Router, private route: ActivatedRoute){
    this.yesterday = moment().startOf('day').toDate();
    this.startingDate = moment().startOf('day').toDate();
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.webCity = params['webCity'];
      this.updateJsonData();
      this.updateHeadTag(this.webCity);
      this.startLoader();
      this.loadDataWithCache();
    });
    this.fetchCityDays();
    this.fetchBlockedDates();
    this.wowService.init();
    this.setupNavbarTogglerListener();

    if (typeof bootstrap !== 'undefined') {
      var myCarousel = new bootstrap.Carousel(document.getElementById('carousel-example-generic'), { interval: 6000, wrap: true });
    } else {
      console.error('Bootstrap nije učitan. Proverite da li je Bootstrap uključen u projekt.');
    }

    const navLinks = document.querySelectorAll('.nav-link');
    navLinks.forEach(link => {
      link.addEventListener('click', () => {
        const navbarToggler = document.querySelector('.navbar-toggler');
        const navbarCollapse = document.querySelector('.navbar-collapse');
        if (navbarToggler instanceof HTMLElement && navbarCollapse instanceof HTMLElement) {
          navbarToggler.classList.add('collapsed');
          navbarCollapse.classList.remove('show');
        }
      });
    });
    this.loadPayPalScript().then(() => {
      this.payPalMethod();
    });
    this.yesterday.setDate(this.yesterday.getDate() - 1);
    this.fetchReviews();
  }
  updateJsonData(){
    this.websiteService.updateJsonData(this.webCity).subscribe(
      response => {
        // Obradi uspešan odgovor ovde
        console.log('Success response:', response);
      },
      error => {
        console.error('Error fetching reviews:', error);
      }
    );
  }


  rate(star: number) {
    this.setRating = star;
    this.updateStarsArray();
    this.onChangeReview();
  }
  private updateStarsArray() {
    this.starsArray = Array.from({ length: this.setRating }, (_, index) => index + 1);
  }

  updateStars() {
    this.fullStars = Array(Math.floor(this.rating)).fill(0);
    this.halfStar = this.rating % 1 !== 0;
    this.emptyStars = Array(5 - Math.ceil(this.rating)).fill(0);
  }

  getHighRatingReview() {
    const numericReviewsArray = this.reviewsArray.map(review => ({
      ...review,
      rating: typeof review.rating === 'string' ? parseFloat(review.rating) : review.rating,
      date: this.datePipe.transform(review.date, 'MMM yyyy')
    }));
    const highRatingReviews = numericReviewsArray.filter(review => review.rating === 4 || review.rating === 5);
    if (highRatingReviews.length > 0) {
      const randomIndex = Math.floor(Math.random() * highRatingReviews.length);
      this.highlightedReview = highRatingReviews[randomIndex];
      this.fullStarsHgh = Array(Math.floor(this.highlightedReview!.rating)).fill(0);
    }
  }

  onChangeReview(): void {
    if(this.websiteService.validateRewName() && this.websiteService.validateRewDesc() && this.setRating !== 0){
      this.isDisabledRew = false;
    }else{
      this.isDisabledRew = true;
    }
  }

  onRewSubmit() {
    this.reviewDate = new Date();
    const newReview = {
        city: this.webCity,
        date: this.reviewDate,
        name: this.inputReviewName,
        rating: this.setRating,
        desc: this.inputReviewDesc
    };
    this.websiteService.addNewReview(newReview).subscribe(
        (response) => {
          console.log('Review successfully created:', response);
          setTimeout(() => {this.successReview = false;}, 30000);
          this.successReview = true;
          this.isDisabledRew = true;
          this.inputReviewName = '';
          this.inputReviewDesc = '';
          this.setRating = 0;
        },(error) => {console.error('Error creating review:', error);
          this.isDisabledRew = true;
        }
    );
}

fetchReviews() {
  this.websiteService.getAllReviews(this.webCity).subscribe(
    (reviews: any[]) => {
      this.reviewsArray = reviews.map(review => ({
        id: review.id,
        date: review.date,
        name: review.name,
        rating: review.rating,
        desc: review.desc
      }));
      console.log("Fetched reviews: ", this.reviewsArray);
      this.getHighRatingReview();
      this.calculateReviews();
    },
    (error) => {console.error('Error fetching reviews:', error);});
}

calculateReviews() {
  if (this.reviewsArray.length === 0) {
    this.rating = 0;
    this.totalReviews = 0;
    return;
  }
  this.totalReviews = this.reviewsArray.length;
  const totalRating = this.reviewsArray.reduce((sum, reviewsArray) => sum + reviewsArray.rating, 0);
  const averageRating = totalRating / this.totalReviews;
  if (Number.isInteger(averageRating)) {
    this.rating = averageRating;
  } else {
    this.rating = parseFloat(averageRating.toFixed(1));
  }
  this.updateStars();
}

  updateHeadTag(city: string): void {
    this.titleService.setTitle(`Pub Crawl ${city} - Nightlife Tour | Vibrant Nightout`);
      this.metaService.updateTag({ name: 'title', content: `Pub Crawl ${city} - Nightlife Tour | Vibrant Nightout`});
      this.metaService.updateTag({ name: 'description', content: `Experience ${city}'s vibrant nightlife with our exciting pub crawl! Discover the best bars and clubs, and enjoy an unforgettable night out.` });
      this.updateURLlLinks(city);
      this.updateBaseURL(city);
      this.updateJsonLd(city);
  }
  updateURLlLinks(city: string): void {
    const url = `https://replaypubcrawl.com/${city}`;
    const existingLink = document.querySelector('link[rel="canonical"]');
    if (existingLink) {
      this.renderer.setAttribute(existingLink, 'href', url);
    } else {
      const link: HTMLLinkElement = this.renderer.createElement('link');
      this.renderer.setAttribute(link, 'rel', 'canonical');
      this.renderer.setAttribute(link, 'href', url);
      this.renderer.appendChild(document.head, link);
    }
    const existingOgUrl = document.querySelector('meta[property="og:url"]');
    if (existingOgUrl) {
        this.renderer.setAttribute(existingOgUrl, 'content', url);
    } else {
        const meta: HTMLMetaElement = this.renderer.createElement('meta');
        this.renderer.setAttribute(meta, 'property', 'og:url');
        this.renderer.setAttribute(meta, 'content', url);
        this.renderer.appendChild(document.head, meta);
    }
  }
  updateBaseURL(city: string): void {
    const baseTag = document.querySelector('base');
    if (baseTag) {
      baseTag.setAttribute('href', `/${city}/`);
    } else {
      const newBaseTag = document.createElement('base');
      newBaseTag.setAttribute('href', `/${city}/`);
      document.head.appendChild(newBaseTag);
    }
  }

  private updateJsonLd(city: string): void {
    const jsonFileUrl = `./../../assets/microdata/${city}.json`;
    fetch(jsonFileUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        let script = document.querySelector('script[type="application/ld+json"]') as HTMLScriptElement;
        if (!script) {
          script = document.createElement('script') as HTMLScriptElement;
          script.type = 'application/ld+json';
          document.head.appendChild(script);
        }
        script.textContent = JSON.stringify(data);
      })
  }

  getLocalStorageData(key: string) {
    const data = localStorage.getItem(key);
    if (data) {
      const parsedData = JSON.parse(data);
      const currentTime = new Date().getTime();
      if (parsedData.expiry > currentTime) {
        return parsedData.value;
      } else {
        localStorage.removeItem(key);
      }
    }
    return null;
  }

  setLocalStorageData(key: string, value: any) {
    const currentTime = new Date().getTime();
    const expiry = currentTime + 30 * 24 * 60 * 60 * 1000; // 30 dana u milisekundama
    const data = { value, expiry };
    localStorage.setItem(key, JSON.stringify(data));
  }

  loadDataWithCache() {
    const cacheKey = `webPublicData_${this.webCity}`;
    const cachedData = this.getLocalStorageData(cacheKey);

    if (cachedData) {
      this.processData(cachedData);
    } else {
      this.websiteService.getAllPublicDataByCity(this.webCity).subscribe(data => {
        this.setLocalStorageData(cacheKey, data);
        this.processData(data);
      });
    }
  }

  processData(data: any) {
    this.webPublicData = data;
    this.tourArray = this.websiteService.extractTourArray(data);
    this.moreArray = this.websiteService.extractMoreArray(data);
    this.socialArray = this.websiteService.extractSocialArray(data);
    this.footerHeaderArray = this.websiteService.extractFooterHeaderArray(data);
    this.seoKeywords = this.websiteService.extractSeoData(data);
    this.galleryWeb = Array.isArray(data.webGallery) ? data.webGallery : [];
    this.faqItems = Array.isArray(data.webFaq) ? data.webFaq : [];
    this.clubWeb = Array.isArray(data.webClub) ? data.webClub : [];
    this.stayWeb = Array.isArray(data.webStay) ? data.webStay : [];
    // GET PRICE FROM MORE H1 (PAYPAL)
    this.priceFromArray = this.websiteService.getPriceFromArray(data);
    this.inputPricePerPerson = parseFloat(this.priceFromArray[0].more_h1);
    this.calculateTotal();
    this.websiteService.getCitiesOnly().subscribe(data => {
        if (Array.isArray(data)) {
            const currentCity = data.find(city => city.web_city_name === this.webCity);
            if (currentCity) {
                this.webActive = currentCity.web_city_available;
            } else {
                console.error("City not found:", this.webCity);
            }
        }
      });
  }

  calculateTotal(): void {
    const subtotal = this.inputTotalPeople * this.inputPricePerPerson;
    const fee = subtotal * 0.1;
    const total = subtotal + fee;
    this.subtotal = subtotal;
    this.fee = fee;
    this.total = total;
  }
  onInputChange(): void {
    this.calculateTotal();
    this.isBookingValid();
  }
  onDateChange(event: any): void {
    const selectedDate = moment.tz(event, this.timezone).startOf('day').toDate();
    if (!isNaN(selectedDate.getTime())) {
      this.inputDate = selectedDate;
    }
    if (this.inputDate) {
        this.dateToSql = moment(this.inputDate).format('YYYY-MM-DD');
        console.log("Datum u pickeru: ", this.dateToSql);
    } else {
        console.log("Datum nije validan.");
    }
    this.isBookingValid();
}
  //================================== LOADER ====================================
  startLoader(): void {
  const loader = document.querySelector(".loading-page") as HTMLElement | null;
  let c = 0;

  const interval = setInterval(() => {
    const counterElement = document.querySelector(".loading-page .counter h2") as HTMLElement | null;
    const hrElement = document.querySelector(".loading-page .counter hr") as HTMLElement | null;
    if (counterElement && hrElement) {
      counterElement.textContent = `${c}%`;
      hrElement.style.width = `${c}%`;
    }
    c++;
    if (c === 101) {
      clearInterval(interval);
      if (loader) {
        loader.style.opacity = '0';
        loader.style.transition = 'visibility 0.5s, opacity 0.2s linear';
        loader.style.overflow = 'hidden';
        setTimeout(() => {
          loader.style.display = 'none';
        }, 200);
      }
      const homeElement = document.getElementById("home");
      if (homeElement) {
        homeElement.removeAttribute("fixAttr");
      }
    }
  }, 16);
}

  //============================= PAYMENT SECTION ================================
  toggleSection() {
    this.showSection = !this.showSection;
  }
  openSection(){
    this.showSection = true;
    const bookElement = document.getElementById('booknow');
    if (bookElement) {
      bookElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
  closeSection() {
    this.showSection = false;
  }
  validateName() {
    return this.bookingService.validateName();
  }
  validatePhone() {
    return this.bookingService.validatePhone();
  }
  validateEmail() {
    return this.bookingService.validateEmail();
  }
  validateDate() {
    if (!this.inputDate) return false;
    const date = new Date(this.inputDate);
    const selectedDate = date.setHours(0, 0, 0, 0);
    const today = new Date().setHours(0, 0, 0, 0);
    return selectedDate >= today && this.filterFunction(date);
  }
  validateTotalPeople() {
    return this.bookingService.validateTotalPeople();
  }
  isBookingValid() {
    if(this.bookingService.validateName() && this.bookingService.validatePhone() && this.bookingService.validateEmail() && this.validateDate() && this.bookingService.validateTotalPeople()){
      this.isDisabled = false;
    }else{
      this.isDisabled = true;
    }
  }

  private loadPayPalScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (document.getElementById('paypal-sdk')) {
        resolve();
        return;
      }
      const script = document.createElement('script');
      script.id = 'paypal-sdk';
      // PayPal Sandbox Testing SDK
      // script.src = 'https://www.paypal.com/sdk/js?currency=EUR&client-id=AXWmInK6wHAJUO131aCwntNhhiuuEi1MRes1U8XRf9ywjBjMo5zQGP2cI6jNqXhIqHhJDaU1_y0Vc1q0&components=buttons';
      script.src = 'https://www.paypal.com/sdk/js?currency=EUR&client-id=AbqeHnMLt5yK6e7BBdljJUirNnwPjDr7y2H0lJSj-o9t_wTNFQ8nI1izRDbkcejI_3CA5k6Cl0ujUW0V&components=buttons';
      script.async = true;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error('Failed to load PayPal SDK'));

      document.head.appendChild(script);
    });
  }
  payPalMethod() {
    if (window.paypal) {
      window.paypal.Buttons({
        createOrder: (data: any, actions: any) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: this.total.toString()
                }
              }
            ]
          });
        },
        onApprove: (data: any, actions: any) => {
          return actions.order.capture().then((details: any) => {
            this.createBookingOnSuccess();
          });
        },
        onError: (error: any) => {
          this.isErrorPayment = true;
        },
      }).render(this.paymentRef.nativeElement);
    } else {
      console.error('PayPal SDK nije učitan.');
    }
  }
  createBookingOnSuccess(){
    const newBooking: Booking = {
      city: this.webCity,
      name: this.inputName,
      phone_number: this.inputPhone,
      date: this.dateToSql,
      payment_type: this.inputPaymentType,
      total_people: this.inputTotalPeople,
      total_money: this.subtotal,
    };
    const clientConfirmation = {
      name: this.inputName,
      date: this.dateToSql,
      total_people: this.inputTotalPeople,
      email: this.inputEmail
    }
    console.log("Booking: ", newBooking);

    this.bookingService.create(newBooking).subscribe(
      (error) => {
        console.error('Error create booking:', error);
      }
    );
    this.websiteService.sendEmail(newBooking).subscribe(
      (error) => {
        console.error('Error sending email:', error);
      }
    );
    this.websiteService.sendEmailConfirmation(clientConfirmation).subscribe(
      (error) => {
        console.error('Error sending email:', error);
      }
    );
    this.inputName = '';
    this.inputPhone = '';
    this.inputEmail = '';
    this.inputTotalPeople = 1;
    this.isCompletedPayment = true;
    this.isDisabled = true
    this.calculateTotal();
  }
  //=============================== GALLERY MODAL ==================================
  openModal(event: MouseEvent) {
    const target = event.target as HTMLImageElement;
    this.modalImageUrl = target.src;
    this.isModalOpen = true;
  }
  closeModal() {
    this.isModalOpen = false;
  }
  //=============================== CONTACT SOCIAL ICONS ==================================
  whatsappOpen() {
    if (this.socialArray && this.socialArray.length > 0) {
      const whatsapp = this.socialArray[0].whatsapp;
      if (whatsapp) {
        window.open(`https://wa.me/${whatsapp}`, '_blank');
      } else {
        console.error('WhatsApp broj nije dostupan.');
      }
    } else {
      console.error('Nema dostupnih informacija o društvenim mrežama.');
    }
  }
  telegramOpen() {
    if (this.socialArray && this.socialArray.length > 0) {
      const telegram = this.socialArray[0].telegram;
      if (telegram) {
        window.open(`https://t.me/${telegram}`, '_blank');
      } else {
        console.error('Telegram broj nije dostupan.');
      }
    } else {
      console.error('Nema dostupnih informacija o društvenim mrežama.');
    }
  }
  viberOpen() {
    if (this.socialArray && this.socialArray.length > 0) {
      const viber = this.socialArray[0].viber;
      if (viber) {
        window.open(`viber://chat?number=${viber}`, '_blank');
      } else {
        console.error('Viber broj nije dostupan.');
      }
    } else {
      console.error('Nema dostupnih informacija o društvenim mrežama.');
    }
  }
  instagramOpen() {
    if (this.socialArray && this.socialArray.length > 0) {
      const instagram = this.socialArray[0].instagram;
      if (instagram) {
        window.open(`https://instagram.com/${instagram}`, '_blank');
      } else {
        console.error('Instagram broj nije dostupan.');
      }
    } else {
      console.error('Nema dostupnih informacija o društvenim mrežama.');
    }
  }
  //============= NAVBAR TOGGLER & NAVBAR OPACITY ON SCROLL POSITION =================
  private setupNavbarTogglerListener(): void {
    const navbarToggler = document.querySelector('.navbar-toggler');
    const navigation = document.getElementById('navigation');
    const backTop = document.getElementById('back-top');

    if (navbarToggler && navigation && backTop) {
      navbarToggler.addEventListener('click', () => {
        const ariaExpanded = navbarToggler.getAttribute('aria-expanded') === 'true';
        const classToAdd = ariaExpanded ? 'navigation-black' : 'navigation-transparent';
        const classToRemove = ariaExpanded ? 'navigation-transparent' : 'navigation-black';

        navigation.classList.remove(classToRemove);
        navigation.classList.add(classToAdd);
      });

      window.addEventListener('scroll', () => {
        const ariaExpanded = navbarToggler.getAttribute('aria-expanded') === 'true';
        if (!ariaExpanded){
          if (window.scrollY > 400) {
            navigation.classList.remove('navigation-transparent');
            navigation.classList.add('navigation-black');
            backTop.style.opacity = '1';
          } else {
            navigation.classList.remove('navigation-black');
            navigation.classList.add('navigation-transparent');
            backTop.style.opacity = '0';
          }
        }
      });
    }
  }

  //===================== CAROUSEL LOOP ======================
  startCarousel() {
    const carouselElement = this.carousel.nativeElement;
    if (carouselElement) {
      const carousel = new bootstrap.Carousel(carouselElement);
    }
  }
  //===================== SCROLL TO ELEMENT ======================
  scrollToElement(id: string, offset: number) {
    const element = document.getElementById(id);
    if (element) {
      const topPos = element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: topPos - offset,
        behavior: 'smooth'
      });
    }

  }
  scrollToSection(sectionId: string) {
    const offset = 40;
    const sectionElement = this.gallerySection.nativeElement;
    if (sectionElement) {
      const targetPosition = sectionElement.getBoundingClientRect().top + window.pageYOffset - offset;
      window.scrollTo({ top: targetPosition, behavior: 'smooth' });
      const navbarCollapse = document.querySelector('.navbar-collapse') as HTMLElement;
      navbarCollapse?.classList.remove('show');
    }
  }
  // ============================ WORKING DAYS ==============================
  fetchCityDays() {
    this.websiteService.getCityDays(this.webCity).subscribe((data: any[]) => {
      this.webDays = data;
      if (data.length === this.selected.length) {
        this.selected = data.map(item => item.day_available);
      } else {
        console.error("Greška: Dobijeni niz nema istu dužinu kao niz selected.");
      }
      this.workDays = this.webDays
        .filter(day => day.day_available && typeof day.day_number === 'number')
        .map(day => day.day_number ? day.day_number - 1 : undefined)
        .filter((day): day is number => typeof day === 'number');

      this.workDaysNames = this.webDays
        .filter(day => day.day_available)
        .map(day => day.day_name)
        .filter((name): name is string => typeof name === 'string');
      const firstAvailableDay = this.webDays.find(day => day.day_available);
        if (firstAvailableDay) {
            this.hours = firstAvailableDay.hours;
        } else {
            console.error("Greška: Nema dostupnih dana u nizu.");
        }
    });
  }
  fetchBlockedDates(): void {
    this.websiteService.getBlockedDates(this.webCity).subscribe(
      dates => {
        this.blockedDates = dates.map(d => new Date(d.date_block));
      },
      error => {
        console.error('Greška prilikom učitavanja blokiranih datuma:', error);
      }
    );
  }
  // DATEPICKER ZA PRIKAZ BLOKIRANIH DATUMA I NERADNIH DANA
  filterFunction: DateFilterFn<Date | null> = (date: Date | null): boolean => {
    if (!date) return false;
    let day = date.getDay();
    if (day === 0) day = 7;
    const isAllowedDay = this.workDays.includes(day - 1);
    const time = date.setHours(0, 0, 0, 0);
    const isBlockedDate = this.blockedDates.some(x => x.setHours(0, 0, 0, 0) === time);
    return !isBlockedDate && isAllowedDay && date >= this.startingDate; // Dodato ograničenje
  };

  private setVideoProperties(): void {
    if (this.videoplayer) {
      const media = this.videoplayer.nativeElement;
      media.muted = true; // Osigurajte da je video mutiran
      media.play().catch(error => {
        console.error('Playback failed:', error);
      });
    }
  }
  openTermsDialog() {
    this.dialog.open(TermsConditionComponent);
  }
  openPrivacyDialog() {
    this.dialog.open(PrivacyPolicyComponent);
  }
  openRefundDialog() {
    this.dialog.open(RefundPolicyComponent);
  }
  ngAfterViewInit() {
    this.setVideoProperties();
    const links = document.querySelectorAll('a[href^="#"]');
    links.forEach(link => {
      link.addEventListener('click', (event) => {
        event.preventDefault();
        const targetId = link.getAttribute('href');
        const body = document.body;
        const navBurger = document.querySelector('.ham');
        if (targetId && navBurger) {
          const offset = 40;
          this.scrollToElement(targetId.substring(1), offset);
          body.style.overflowY = 'auto';
          navBurger.classList.remove('active');
        }
      });
    });
    this.cdr.detectChanges();
  }
}
