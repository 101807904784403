  <div class="banerPhoto">
    <div class="overlayChooseCity"></div>
    <div class="centeredContainer">
      <div class="centeredContent">
        <div class="cityItem" *ngFor="let city of dbCities" [routerLink]="['/' + city.name]" >
          <div class="cityLogo">
            <img [src]="'./../../../../assets/cities/' + city.url +'.svg'" [alt]="'Pub Crawl for ' + city.name + ' city - country flag'">
          </div>
          <p class="cityName">{{ city.name }}</p>
        </div>
      </div>
    </div>
  </div>
  <div id="startHomeInfoDiv">
    <div id="home-text">
      <h1>Experience the Ultimate Nightlife with Replay Pub Crawl - Bar Crawls and Pub Crawls!</h1>
      <p>Experience the ultimate nightlife adventure in style as you explore all the wonders that cities have to offer at night.
        Select your city and join the best pub crawl and bar crawl tours!</p>
      <p><strong>Highlights:</strong></p>
      <ul>
        <li>You won’t miss the busiest nightlife spots in each city.</li>
        <li>Find out what locals are drinking and try it out for yourself.</li>
        <li>Book now for a guaranteed fun nightlife tour in the city.</li>
      </ul>
      <h2>Explore Our Nightlife Tours</h2>
      <p>Our nightlife tours take you through the best bars, pubs, and clubs in the following cities:</p>
      <ul>
        <li>
          <a href="https://replaypubcrawl.com/Belgrade">Belgrade</a>: Dive into the heart of Serbia's nightlife and discover the top spots to enjoy great drinks and music.
        </li>
        <li>
          <a href="https://replaypubcrawl.com/Budva">Budva</a>: Experience the lively beach bars and nightclubs of this Montenegrin hotspot.
        </li>
        <li>
          <a href="https://replaypubcrawl.com/Herceg%20Novi">Herceg Novi</a>: Explore the coastal nightlife and enjoy a memorable evening in this charming town.
        </li>
        <li>
          <a href="https://replaypubcrawl.com/Tivat">Tivat</a>: Discover the vibrant nightlife scene and exclusive venues of Tivat.
        </li>
        <li>
          <a href="https://replaypubcrawl.com/Kotor">Kotor</a>: Enjoy a unique pub crawl experience in the stunning coastal city of Kotor.
        </li>
        <li>
          <a href="https://replaypubcrawl.com/Ulcinj">Ulcinj</a>: Immerse yourself in the lively bar scene and local culture of Ulcinj.
        </li>
        <li>
          <a href="https://replaypubcrawl.com/Sveti%20Stefan">Sveti Stefan</a>: Experience the luxury and excitement of nightlife on this picturesque island.
        </li>
        <li>
          <a href="https://replaypubcrawl.com/Bar">Bar</a>: Explore the diverse nightlife options and energetic atmosphere in Bar.
        </li>
        <li>
          <a href="https://replaypubcrawl.com/Split">Split</a>: Party in one of Croatia's most famous cities with a dynamic nightlife scene.
        </li>
        <li>
          <a href="https://replaypubcrawl.com/Dubrovnik">Dubrovnik</a>: Enjoy a unique nightlife tour in the historic and beautiful Dubrovnik.
        </li>
        <li>
          <a href="https://replaypubcrawl.com/Hvar">Hvar</a>: Discover the lively bar scene and stunning views on the island of Hvar.
        </li>
        <li>
          <a href="https://replaypubcrawl.com/Zadar">Zadar</a>: Experience the vibrant nightlife and cultural charm of Zadar.
        </li>
        <li>
          <a href="https://replaypubcrawl.com/Zagreb">Zagreb</a>: Explore the lively bars and nightlife in Croatia's capital city.
        </li>
        <li>
          <a href="https://replaypubcrawl.com/Pula">Pula</a>: Enjoy an exciting nightlife tour in Pula, known for its rich history and vibrant nightlife.
        </li>
        <li>
          <a href="https://replaypubcrawl.com/Rovinj">Rovinj</a>: Experience the charming nightlife and picturesque setting of Rovinj.
        </li>
        <li>
          <a href="https://replaypubcrawl.com/Ljubljana">Ljubljana</a>: Dive into the vibrant pub scene of Slovenia's capital city.
        </li>
        <li>
          <a href="https://replaypubcrawl.com/Rome">Rome</a>: Explore the historic and lively nightlife of the Eternal City.
        </li>
        <li>
          <a href="https://replaypubcrawl.com/Palermo">Palermo</a>: Enjoy the exciting bar scene and cultural experiences in Palermo.
        </li>
        <li>
          <a href="https://replaypubcrawl.com/Paceville">Paceville</a>: Experience the energetic nightlife and party scene in Malta’s premier entertainment district.
        </li>
      </ul>
      <h2>Why Choose REPLAY Pub Crawl?</h2>
      <p>At REPLAY Pub Crawl, we provide an unmatched nightlife experience. Our tours are carefully crafted to ensure you have a fantastic time in each city, with expert guides, exclusive access to top venues, and a fun atmosphere. Whether you're a solo traveler, couple, or group, our nightlife tours promise vibrant nightout and unforgettable memories. It is highly recommended to bring with you positive energy, and you decide how much time you will spend in each pub on this tour. We want to give you the best price in the current time.</p>
      <p>Ready to explore the nightlife in Belgrade, Budva, Dubrovnik, and beyond? Book your REPLAY Pub Crawl tour today and prepare for an epic nightlife adventure in some of Europe's most exciting cities!</p>
    </div>
    <div id="advertisement">
      <h2>Join Us for an Unforgettable Nightlife Adventure!</h2>
      <p>Don't miss out on an incredible night out. From the historic streets of Dubrovnik to the lively beach bars of Budva, our nightlife tours are designed to give you an amazing experience in each city. Get ready to enjoy great drinks, meet fellow travelers, and dance the night away in beautiful locations across Europe.</p>
      <ul>
        <li>Get ready with provided glow paint</li>
        <li>Perfect for solo travelers, couples, and small groups</li>
        <li>A small group ensures that you receive personalized attention from your guide</li>
      </ul>
    </div>
  </div>
