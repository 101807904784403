import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

import { ChooseCityComponent } from './website/components/choose-city/choose-city.component';
import { WebsiteComponent } from './website/website.component';
import { PartnersComponent } from './website/components/partners/partners.component';
import { ErrorComponent } from './website/components/error/error.component';
import { PublicCityGuard } from './website/guard/public-city.guard';



const routes: Routes = [
  { path: 'mikrobuva', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)},
  { path: '', component: ChooseCityComponent },
  { path: 'error', component: ErrorComponent },
  { path: ':webCity', component: WebsiteComponent, canActivate: [PublicCityGuard] },
  { path: 'partners/:webCity', component: PartnersComponent, canActivate: [PublicCityGuard] },
  { path: '**', redirectTo: '/error' }
];


const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled'
};
@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
