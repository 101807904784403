<div class="loading-page">
  <div class="counter">
      <hr/>
      <h2>0%
      </h2>
  </div>
</div>
<mat-drawer-container class="sidenav-container" [hasBackdrop]="true">
  <mat-drawer #sidenav class="sidenav" fixedInViewport mode="push" position="end">
    <h2>Admin Panel</h2>
    <mat-nav-list>
      <mat-list-item routerLink="/mikrobuva/bookings" routerLinkActive="active-link" (click)="sidenav.close()">Bookings</mat-list-item>
      <mat-list-item *ngIf="isAdminUser" routerLink="/mikrobuva/users" routerLinkActive="active-link" (click)="sidenav.close()">Users</mat-list-item>
      <mat-list-item *ngIf="isAdminUser" routerLink="/mikrobuva/websites" routerLinkActive="active-link" (click)="sidenav.close()">Website</mat-list-item>
      <mat-list-item *ngIf="isAdminUser" routerLink="/mikrobuva/statistics" routerLinkActive="active-link" (click)="sidenav.close()">Statistics</mat-list-item>
    </mat-nav-list>
  </mat-drawer>
  <mat-drawer-content>
    <div class="menuDiv d-flex justify-content-end" >
      <button class="navMenuButton" *ngIf="(isLoggedIn && isAdminUser && isMobile && ((isBookingsComponentLoaded | async) || (currentRoute !== '/mikrobuva/login' && currentRoute !== '/mikrobuva/bookings')))" mat-icon-button (click)="sidenav.toggle()">
        <i class="fa fa-bars"></i>
      </button>
    </div>
    <div class="container mt-5 adminComponents" id="headContainer" fixAttr>
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
