<h4>Refund Policy</h4>
<mat-dialog-content class="mat-typography">
  <h3>Cancellation</h3>
  <p>
    Our tour come with a 48-hour free cancellation policy, meaning you can cancel your booking up until 48 hours before the tour starts to receive a full refund.
  </p>
  <h3>Refund request</h3>
  <p>
    If you cancel your booking within the cancellation policy, your refund will be automatically processed via the same payment method you used to book the pub crawl tour.
    You should see it in your account within 3-5 business days.
  </p>
  <p>
    In the unlikely event that the REPLAY Pub Crawl needs to cancel your booking, you are always entitled to a full refund.
    Your refund will be automatically processed via the same payment method you used to book the tour.
    You should see it in your account within 3-5 business days.
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <p mat-dialog-close>CLOSE</p>
</mat-dialog-actions>
