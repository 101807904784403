import { Component, OnInit, Renderer2 } from '@angular/core';
import { BodyOther, ClubWeb } from '../../models/website.model';
import { WebsiteService } from '../../services/website.service';
import { ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./../../website.component.css']
})
export class PartnersComponent implements OnInit {

  webCity!: string;
  webStays: any = [];
  footerHeaderArray?: BodyOther[];

  clubWeb?: ClubWeb[];

  constructor(private websiteService: WebsiteService, private route: ActivatedRoute, private renderer: Renderer2, private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.webCity = params['webCity'];
      this.updateHeadTag(this.webCity);
      this.fetchAllPublicDataByCity();
    });
    document.body.style.overflowY = 'auto';
  }

  fetchAllPublicDataByCity() {
    this.websiteService.getAllPublicDataByCity(this.webCity).subscribe(data => {
      this.webStays = data.webStay;
      this.clubWeb = Array.isArray(data.webClub) ? data.webClub : [];
      this.footerHeaderArray = this.websiteService.extractFooterHeaderArray(data);
    });
  }
  updateHeadTag(city: string): void {
    this.titleService.setTitle(`REPLAY Pub Crawl ${city} - Partners`);
      this.metaService.updateTag({ name: 'title', content: `REPLAY Pub Crawl ${city} - Partners`});
      this.metaService.updateTag({ name: 'description', content: `Explore the best bars, clubs, pubs and stays in cities like ${city}, featuring top nightlife venues and premium hotels for an exceptional experience.` });
      this.updateURLlLinks(city);
      this.updateBaseURL(city);
  }
  updateURLlLinks(city: string): void {
    const url = `https://replaypubcrawl.com/partners/${city}`;
    const existingLink = document.querySelector('link[rel="canonical"]');
    if (existingLink) {
      this.renderer.setAttribute(existingLink, 'href', url);
    } else {
      const link: HTMLLinkElement = this.renderer.createElement('link');
      this.renderer.setAttribute(link, 'rel', 'canonical');
      this.renderer.setAttribute(link, 'href', url);
      this.renderer.appendChild(document.head, link);
    }
    const existingOgUrl = document.querySelector('meta[property="og:url"]');
    if (existingOgUrl) {
        this.renderer.setAttribute(existingOgUrl, 'content', url);
    } else {
        const meta: HTMLMetaElement = this.renderer.createElement('meta');
        this.renderer.setAttribute(meta, 'property', 'og:url');
        this.renderer.setAttribute(meta, 'content', url);
        this.renderer.appendChild(document.head, meta);
    }
  }
  updateBaseURL(city: string): void {
    const baseTag = document.querySelector('base');
    if (baseTag) {
      baseTag.setAttribute('href', `/partners/${city}/`);
    } else {
      const newBaseTag = document.createElement('base');
      newBaseTag.setAttribute('href', `/partners/${city}/`);
      document.head.appendChild(newBaseTag);
    }
  }

}
