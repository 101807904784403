import { WebsiteService } from 'src/app/website/services/website.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PublicCityGuard implements CanActivate {

  dbCities: string[] = [];

  constructor(private router: Router, private websiteService: WebsiteService) {
    this.fetchWebsiteCities();
  }

  fetchWebsiteCities() {
    this.websiteService.getCitiesOnly().subscribe(data => {
      if (Array.isArray(data)) {
        this.dbCities = data.map(city => city.web_city_name || '');
      }
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<boolean> {
    const requestedCity = route.params['webCity'];
    return this.websiteService.getCitiesOnly().pipe(
      map(() => {
        const isValid = this.dbCities.includes(requestedCity);
        if (isValid) {
          return true;
        } else {
          this.router.navigate(['/error']);
          return false;
        }
      })
    );
  }
}
