import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { META_INFO } from './meta.config';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// const baseUrl = 'http://localhost:8080/api/website';
const baseUrl = 'https://replaypubcrawl.com/api/website';


@Injectable({
  providedIn: 'root'
})
export class HeadtagService {


  webCity?:string | null;
  webData: any[] | undefined;
  seoKeywords!: string;



  constructor(private title: Title, private meta: Meta, private router: Router, private activatedRoute: ActivatedRoute, private http: HttpClient) {
    this.init();
  }
  init() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.router.routerState.root),
      map(route => {
        let child = route;
        while (child.firstChild) {
          child = child.firstChild;
        }
        return child;
      }),
      mergeMap(route => route.paramMap),
      tap(params => {
        this.webCity = params.get('webCity');
        if (this.webCity) {
          this.updateMeta(this.webCity);
          this.fetchAllPublicDataByCity();
        }
      })
    ).subscribe();
  }

  fetchAllPublicDataByCity() {
    this.getAllPublicDataByCity(this.webCity).subscribe(data => {
      this.webData = data;
      this.seoKeywords = this.extractSeoData(data);
      if (this.seoKeywords) {
        this.updateHeadTag('', '', this.seoKeywords);
      }
    });
  }
  extractSeoData(data: any) {
    const webBody = data.webBody[0];
    const seoKeywords =   webBody.seo_keywords;
    return seoKeywords;
  }
  getAllPublicDataByCity(web_city_name: any): Observable<any> {
    const url = `${baseUrl}/alldatabycity/${web_city_name}`;
    return this.http.get<any>(url).pipe();
  }
  updateMeta(webCity: string) {
    const routeKeys = Object.keys(META_INFO);
    const matchedSubRoute = routeKeys.find(route => this.router.url.startsWith(route + '/'));
    if (matchedSubRoute) {
      const metaInfo = META_INFO[matchedSubRoute];
      this.updateHeadTag(metaInfo.title.replace('{{ webCity }}', webCity), metaInfo.description.replace('{{ webCity }}', webCity), metaInfo.keywords.replace('{{ webCity }}', webCity));
      return;
    }
    const matchedRoute = routeKeys.find(route => this.router.url === route);
    if (matchedRoute) {
      const metaInfo = META_INFO[matchedRoute];
      this.updateHeadTag(metaInfo.title.replace('{{ webCity }}', webCity), metaInfo.description.replace('{{ webCity }}', webCity), metaInfo.keywords.replace('{{ webCity }}', webCity));
    }
  }

  getWebCity(): string {
    return this.activatedRoute.snapshot.paramMap.get('webCity') || '';
  }

  updateHeadTag(title: string, description: string, keywords: string){
    if (title) {
      this.title.setTitle(title);
      this.meta.updateTag({ name: 'title', content: title });
    }
    if (description) {
      this.meta.updateTag({ name: 'description', content: description });
    }
    if (keywords) {
      this.meta.updateTag({ name: 'keywords', content: keywords });
    }
  }
}

