import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { HeadtagService } from './website/services/headtag.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  constructor(private router: Router, private headtagService: HeadtagService) { }

  ngOnInit(): void {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.updateMeta(event.urlAfterRedirects);
        }
      });
  }

  updateMeta(route: string) {
    this.headtagService.updateMeta(route);
  }

}
