import { Component, OnInit } from '@angular/core';
import { WebsiteService } from '../../services/website.service';

@Component({
  selector: 'app-choose-city',
  templateUrl: './choose-city.component.html',
  styleUrls: ['./../../website.component.css']
})
export class ChooseCityComponent implements OnInit {
  dbCities: any = [];
  constructor(private websiteService: WebsiteService) { }

  ngOnInit(): void {
    this.fetchWebsiteCities();
  }

  fetchWebsiteCities() {
    this.websiteService.getCitiesOnly().subscribe(data => {
        if (Array.isArray(data)) {
            this.dbCities = data
                .filter(city => city.web_city_available)
                .map(city => ({
                    name: city.web_city_name,
                    url: city.web_city_url,
                    available: city.web_city_available
                }));
        }
    });
  }
}
