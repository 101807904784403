<body id="home">
  <!-- ==================================== Navigation ==================================== -->
  <nav class="navbar navigation-black fixed-top navbar-expand-lg navbar-fixed-top bg-body-tertiary">
    <div class="container-fluid navstay-fluid">
      <a class="backButton" [routerLink]="['/' + webCity]">&larr; BACK</a>
    </div>
  </nav>
  <!--================================== STAYS / HOSTELS ==================================== -->
  <section id="staySection" class="stays">
      <div class="container">
          <div class="row mb50">
              <div class="sec-title text-center mb50 wow rubberBand animated" data-wow-duration="700ms">
                  <h2>Our Recommendation</h2>
                  <div class="devider"><i class="fa-solid fa-bolt"></i></div>
              </div>
              <div class="sec-sub-title text-center wow rubberBand animated" data-wow-delay="300ms" data-wow-duration="1000ms">
                  <p>After an exhilarating night out, we understand the importance of unwinding in comfort.
                      That's why we recommend a welcoming retreat nestled in the heart of Belgrade,
                      where weary night owls can find cozy rooms, and all the amenities needed for a rejuvenating experience.</p>
              </div>
              <div id="staysContainer" class="fadeInUp animated">
                  <div class="stay" *ngFor="let stay of webStays">
                    <div class="stayPhoto">
                      <img class="responsive-img" loading="lazy" [src]="stay.stay_img_url + '800w'" [attr.srcset]="stay.stay_img_url + '600w 600w, ' + stay.stay_img_url + '800w 800w, ' + stay.stay_img_url + '1200w 1200w'" sizes="(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 33vw" [alt]="stay.stay_title">
                    </div>
                    <p>{{ stay.stay_title }}</p>
                    <div id="divOpenStay">
                      <a target="_blank" [href]="stay.web_url"><button type="button" class="btn btn-primary buttonOpenStay">BOOK</button></a>
                    </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
  <!--================================== CLUBS / BARS ==================================== -->
  <section id="clubs" class="clubs" #clubsSection *ngIf="clubWeb && clubWeb.length > 0">
    <div class="sec-title text-center mb50 wow fadeInUp animated" data-wow-duration="700ms">
        <h2>Partners</h2>
        <div class="devider"><i class="fa fa-bolt fa-lg"></i></div>
    </div>
    <ul class="clubsBars" >
      <li class="partnerItem" *ngFor="let club of clubWeb">
        <img [src]="club.club_img_url" loading="lazy" [alt]="club.club_title">
      </li>
    </ul>
  </section>
  <!--================================== Footer ==================================== -->
  <footer id="footer" class="footer" *ngIf="footerHeaderArray">
      <div class="container">
          <div class="row">
              <div class="col-md-12 footerRow">
                  <p class="copyright text-center">
                      Copyright &copy; 2023 <a href="https://www.linkedin.com/in/miomirvukovic/" target="_blank" rel="noopener noreferrer">REPLAY Pub Crawl</a>. All rights reserved.
                  </p>
                  <div class="contactFooter text-center">
                    <p>{{ footerHeaderArray[0].footer_phone }} &nbsp;&nbsp;|&nbsp;&nbsp; </p><p><a [href]="'mailto:' + footerHeaderArray[0].footer_email">{{ footerHeaderArray[0].footer_email }}</a></p>
                  </div>
              </div>
          </div>
      </div>
      <div id="fb-root"></div>
      <script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v17.0" nonce="RQWE1O9i"></script>
      <div class="fb-like facebookDiv" data-href="https://facebook.com/docs/replaypubcrawl" data-width="100px" data-layout="" data-action="" data-size="" data-share="false"></div>
  </footer>
</body>
