import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminComponent } from './admin/admin.component';

import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { FormsModule } from '@angular/forms';
import { WebsiteComponent } from './website/website.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { PartnersComponent } from './website/components/partners/partners.component';
import { ChooseCityComponent } from './website/components/choose-city/choose-city.component';
import { HeadtagService } from './website/services/headtag.service';

import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TermsConditionComponent } from './website/components/terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './website/components/privacy-policy/privacy-policy.component';
import { RefundPolicyComponent } from './website/components/refund-policy/refund-policy.component';


@NgModule({
  declarations: [
    AppComponent,
    WebsiteComponent,
    PartnersComponent,
    ChooseCityComponent,
    TermsConditionComponent,
    PrivacyPolicyComponent,
    RefundPolicyComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ScrollToModule.forRoot(),
    MatNativeDateModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule
  ],
  providers: [
    AdminComponent,
    HeadtagService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
